import axios from "axios";

const state = {
  docId: null,
  doc: { body: "", attachment: [] },
  docs: [],
  attachment: [],
  welcome: null,
  endState__: null,
};

const getters = {
  docId() {
    return state.docId;
  },
  doc() {
    return state.doc;
  },
  docs() {
    return state.docs;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setDocId(state, payload) {
    state.docId = payload;
  },
  setDoc(state, payload) {
    state.doc = payload;
  },
  setAttachment(state, payload) {
    state.doc.attachment = payload;
  },
  setDocs(state, payload) {
    state.docs = payload;
  },
  setWelcome(state, payload) {
    state.welcome = payload;
  },
  endMutations__() {},
};

const actions = {
  // const doc_data = {
  //   type: "notice|faq|resource|welcome",
  //   companyType: "partner|user",
  // };
  async createDoc({ commit }, type) {
    // // console.log("CALL createDoc()");
    try {
      const response = await axios.post("/api/bbs", type);
      // console.log(response.data);
      commit("setDocId", response.data.id);
    } catch (e) {
      console.error(e);
    }
  },

  async getDoc({ commit }, id) {
    // console.log("CALL getDoc()");
    try {
      const response = await axios.get(`/api/bbs/${id}`);
      // console.log("getdoc : ", response.data);
      commit("setDoc", response.data);
      commit("setDocId", response.data.id);
    } catch (e) {
      console.error(e);
    }
  },

  async getAttachment({ commit }, id) {
    // console.log("CALL getAttachment()");
    try {
      const response = await axios.get(`/api/bbs/${id}`);
      // console.log("getdoc : ", response.data);
      commit("setAttachment", response.data.attachment);
      commit("setDocId", response.data.id);
    } catch (e) {
      console.error(e);
    }
  },

  async getDocs({ commit }, type) {
    // console.log("CALL getDocs()");
    try {
      const response = await axios.get(
        `/api/bbs/list?type=${type.bbs}&companyType=${type.co}`
      );
      commit("setDocs", response.data);
    } catch (e) {
      console.error(e);
    }
  },

  async updateDoc({ commit }, article) {
    // console.log("CALL updateUser()");
    try {
      const response = await axios.put(`/api/bbs/${article.docId}`, {
        title: article.title,
        body: article.body,
      });
      // console.log(response.data);
      commit("setDoc", response.data);
    } catch (e) {
      console.error(e);
    }
  },

  async deleteDoc({ commit }, id) {
    // console.log("CALL deleteDoc()");
    try {
      await axios.delete(`/api/bbs/${id}`);
      commit("setDoc", null);
    } catch (e) {
      console.error(e);
    }
  },

  // 사이트 대문 조회
  async getWelcome({ commit }) {
    // console.log("CALL getWelcome()");
    try {
      const response = await axios.get("/api/welcome");
      commit("setWelcome", response.data);
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
