<template>
  <v-dialog v-model="dialogVisible" max-width="700px">
    <v-card>
      <v-card-title>
        <span class="headline pl-4">계정수정</span>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-table class="text-center">
          <tr>
            <td class="th">아이디</td>
            <td>
              <v-card
                class="ma-1 py-2 px-4 text-left text-body-1"
                variant="outlined"
              >
                {{ account_data.account }}&nbsp;</v-card
              >
            </td>
          </tr>
          <tr>
            <td class="th">패스워드</td>
            <td>
              <v-text-field
                v-model="account_data.password"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">이름</td>
            <td>
              <v-text-field
                v-model="account_data.name"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">이메일</td>
            <td>
              <v-text-field
                v-model="account_data.email"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">전화번호</td>
            <td>
              <v-text-field
                v-model="account_data.wiredTel"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">휴대전화</td>
            <td>
              <v-text-field
                v-model="account_data.cellTel"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">메모</td>
            <td>
              <v-text-field
                v-model="account_data.memo"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">주소</td>
            <td>
              <v-text-field
                v-model="account_data.postcode"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              >
                <template v-slot:append>
                  <v-btn color="grey-darken-1" @click="openPostCode"
                    >우편번호 찾기</v-btn
                  >
                </template>
              </v-text-field>
              <v-text-field
                v-model="account_data.address1"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
              <v-text-field
                v-model="account_data.address2"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="th">소속회사</td>
            <td>
              <v-card
                class="ma-1 py-2 px-4 text-left text-body-1"
                variant="outlined"
              >
                {{ account_data.companyName }}&nbsp;
              </v-card>
            </td>
          </tr>

          <tr>
            <td class="th">언어</td>
            <td>
              <v-select
                v-model="account_data.language"
                :items="[{ title: '한국어', language: 'KO-kr' }]"
                item-title="title"
                item-value="language"
                variant="outlined"
                density="compact"
                hide-details
                class="ma-1"
              ></v-select>
            </td>
          </tr>
        </v-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" variant="outlined" @click="closeDialog"
          >닫기</v-btn
        >
        <v-btn
          color="orange-darken-4"
          text
          variant="outlined"
          @click="editAccount"
          >수정</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CeditAccount",
  props: {
    data: Object,
    companys: Array,
  },
  watch: {
    data(newValue) {
      // console.log(newValue);
      this.account_data = newValue;
    },
    type(newValue) {
      console.log(newValue);
      this.account_data.companyId = null;
    },
  },
  data: () => ({
    dialogVisible: false,
    type: "all",
    account_data: {
      account: "", // 아이디
      wiredTel: "", // 유선전화
      cellTel: "", // 휴대전화
      companyId: null, // 회사아이디
      companyName: null, // 회사이름
      companyType: null, // 회사타입 (계정권한)
      email: "", // 이메일
      language: "", // 언어
      memo: "", // 메모
      name: "", // 이름
      postcode: "", // 우편번호
      address1: "", // 도로명주소
      address2: "", // 상세주소
      active: false, // 사용여부
      createdAt: "", // 생성일
      updatedAt: "", // 수정일
    },
  }),
  computed: {
    userType() {
      return this.$store.state.user.type;
    },
    isAdmin() {
      if (this.userType === "admin") {
        return true;
      }
      return false;
    },
    processCompanys() {
      if (this.type === "all") {
        return this.companys;
      }
      return this.companys.filter((company) => company.type === this.type);
    },
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("dialogCloseEvent");
    },
    editAccount() {
      this.$emit("editAccountEvent", this.account_data);
      this.closeDialog();
    },
    openPostCode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.account_data.postcode = data.zonecode;
          this.account_data.address1 = data.roadAddress;
          this.account_data.address2 = "";
        },
      }).open();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.th {
  width: 150;
  background: #eeeeee;
}
</style>
