import axios from "axios";

const state = {
  dashboard: null,
};

const getters = {
  dashboard() {
    return state.dashboard;
  },
};

const mutations = {
  setDashboard(state, payload) {
    state.dashboard = payload;
  },
};

const actions = {
  async getDashboard({ commit }) {
    // console.log("CALL getDevices()");
    try {
      const response = await axios.get(`/api/dashboard`);
      // // console.log("Response:", response.data);
      commit("setDashboard", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
