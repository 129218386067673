import { createRouter, createWebHistory } from "vue-router";
// import store from "../store";

import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import SignUpView from "../views/SignUpView.vue";
import FindOutView from "../views/FindOutView.vue";

const checkSessionCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // Trim any leading or trailing whitespace
    if (cookie.startsWith(name + "=") && cookie.indexOf("expires=") === -1) {
      return true;
    }
  }
  return false;
};

const ifAuthenticated = async (to, from, next) => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const auth = checkSessionCookie("session");
    if (auth) {
      next();
    } else {
      router.push({
        name: "Login",
        params: {
          returnTo: to.path,
          query: to.query,
        },
      });
    }
  } catch (error) {
    console.error("Error while checking authentication:", error);
    // 인증 검사 중 에러가 발생하면 로그인 페이지로 리다이렉션합니다.
    router.push({
      name: "login",
      params: {
        returnTo: to.path,
        query: to.query,
      },
    });
  }
};

const routes = [
  // {
  //   path: "/",
  //   redirect: "/login",
  // },
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/findout",
    name: "FindOut",
    component: FindOutView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user",
    name: "UserMain",
    component: () =>
      import(/* webpackChunkName: "UserMain" */ "../views/UserMainView.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/device/bench",
    name: "UserDeviceBench",
    component: () =>
      import(
        /* webpackChunkName: "UserDeviceBench" */ "../views/UserDeviceBenchView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/device/bed",
    name: "UserDeviceBed",
    component: () =>
      import(
        /* webpackChunkName: "UserDeviceBed" */ "../views/UserDeviceBedView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner",
    name: "PartnerMain",
    component: () =>
      import(
        /* webpackChunkName: "PartnerMain" */ "../views/PartnerMainView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/device/bench",
    name: "PartnerDeviceBench",
    component: () =>
      import(
        /* webpackChunkName: "CommonDeviceBenchView" */ "../views/CommonDeviceBenchView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/device/bed",
    name: "PartnerDeviceBed",
    component: () =>
      import(
        /* webpackChunkName: "CommonDeviceBedView" */ "../views/CommonDeviceBedView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin",
    name: "AdminMain",
    component: () =>
      import(/* webpackChunkName: "AdminMain" */ "../views/AdminMainView.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/product",
    name: "AdminSystemProductMain",
    component: () =>
      import(
        /* webpackChunkName: "AdminSystemProduct" */ "../views/AdminSystemProductView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/company",
    name: "AdminSystemCompany",
    component: () =>
      import(
        /* webpackChunkName: "AdminSystemCompany" */ "../views/AdminSystemCompanyView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/account",
    name: "AdminSystemAccount",
    component: () =>
      import(
        /* webpackChunkName: "AdminSystemAccount" */ "../views/AdminSystemAccountView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/device/bench",
    name: "AdminDeviceBench",
    component: () =>
      import(
        /* webpackChunkName: "CommonDeviceBench" */ "../views/CommonDeviceBenchView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/admin/device/bed",
    name: "AdminDeviceBed",
    component: () =>
      import(
        /* webpackChunkName: "CommonDeviceBed" */ "../views/CommonDeviceBedView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  // partner
  {
    path: "/partner/board/welcome",
    name: "PartnerWelcome",
    component: () =>
      import(
        /* webpackChunkName: "CommonNotice" */ "../views/PartnerWelcomeView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/welcome/create",
    name: "PartnerWelcomeCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/welcome/view",
    name: "PartnerWelcomeViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/welcome/edit",
    name: "PartnerWelcomeEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/notice",
    name: "PartnerNotice",
    component: () =>
      import(
        /* webpackChunkName: "CommonNotice" */ "../views/PartnerNoticeView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/notice/create",
    name: "PartnerNoticeCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/notice/view",
    name: "PartnerNoticeViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/notice/edit",
    name: "PartnerNoticeEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/faq",
    name: "PartnerFaq",
    component: () =>
      import(
        /* webpackChunkName: "PartnerFaq" */ "../views/PartnerFaqView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/faq/create",
    name: "PartnerFaqCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/faq/view",
    name: "PartnerFaqViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/faq/edit",
    name: "PartnerFaqEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/reference",
    name: "PartnerReference",
    component: () =>
      import(
        /* webpackChunkName: "PartnerReference" */ "../views/PartnerReferenceView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/reference/create",
    name: "PartnerReferenceCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/reference/view",
    name: "PartnerReferenceViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/partner/board/reference/edit",
    name: "PartnerReferenceEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  // user
  {
    path: "/user/board/welcome",
    name: "UserWelcome",
    component: () =>
      import(
        /* webpackChunkName: "CommonNotice" */ "../views/UserWelcomeView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/welcome/create",
    name: "UserWelcomeCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/welcome/view",
    name: "UserWelcomeViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/welcome/edit",
    name: "UserWelcomeEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/notice",
    name: "UserNotice",
    component: () =>
      import(
        /* webpackChunkName: "UserNotice" */ "../views/UserNoticeView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/notice/create",
    name: "UserNoticeCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/notice/view",
    name: "UserNoticeViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/notice/edit",
    name: "UserNoticeEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/faq",
    name: "UserFaq",
    component: () =>
      import(/* webpackChunkName: "UserFaq" */ "../views/UserFaqView.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/faq/create",
    name: "UserFaqCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/faq/view",
    name: "UserFaqViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/faq/edit",
    name: "UserFaqEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/reference",
    name: "UserReference",
    component: () =>
      import(
        /* webpackChunkName: "UserReference" */ "../views/UserReferenceView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/reference/create",
    name: "UserReferenceCreateDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/CreateDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/reference/view",
    name: "UserReferenceViewDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/ViewDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/board/reference/edit",
    name: "UserReferenceEditDoc",
    component: () =>
      import(
        /* webpackChunkName: "AdminNoticeCreateDoc" */ "../views/EditDocView.vue"
      ),
    beforeEnter: ifAuthenticated,
  },

  // {
  //   path: "*",
  //   name: "NotFound",
  //   component: () =>
  //     import(/* webpackChunkName: "NotFound" */ "../views/NotFoundView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
