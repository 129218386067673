<template>
  <v-footer app fixed class="footer">
    <v-row>
      <v-col class="text-center">
        ㈜삼명테크 T : 031.768.4121-3 F : 031.768.4124 | 경기도 광주시 초월읍
        동막골길 150-10 (학동리 264-11) | 사업자등록번호 126-81-43090
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "CFooter",
  props: {},
  data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  padding-top: 16px;
  background-color: #f1f1f1;
  z-index: -100 !important;
}

@media (max-width: 650px) {
  .footer {
    display: none;
  }
}
</style>
