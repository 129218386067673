import axios from "axios";

const state = {
  sensorFlag: "-전체-",
  deviceType: null,
  model: null,
  models: [],
  files: [],
  device: null,
  devices: [],
  benchDevices: [],
  bedDevices: [],
  endState__: null,
};

const getters = {
  sensorFlag() {
    return state.sensorFlag;
  },
  deviceType() {
    return state.deviceType;
  },
  model() {
    return state.model;
  },
  models() {
    return state.models;
  },
  files() {
    return state.files;
  },
  device() {
    return state.device;
  },
  devices() {
    return state.devices;
  },
  benchDevices() {
    return state.benchDevices;
  },
  bedDevices() {
    return state.bedDevices;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setSensorFlag(state, payload) {
    state.sensorFlag = payload;
  },
  setDeviceType(state, payload) {
    state.deviceType = payload;
  },
  setModel(state, payload) {
    state.model = payload;
  },
  setModels(state, payload) {
    state.models = payload;
  },
  setFiles(state, payload) {
    state.files = payload;
  },
  setDevice(state, payload) {
    state.device = payload;
  },
  setDevices(state, payload) {
    state.devices = payload;
  },
  setBenchDevices(state, payload) {
    state.benchDevices = payload;
  },
  setBedDevices(state, payload) {
    state.bedDevices = payload;
  },
  endMutations__() {},
};

const actions = {
  // 장치 모델 추가
  async createDeviceModel({ commit }, data) {
    // console.log("CALL createDeviceModel()");
    try {
      const response = await axios.post(
        `/api/device/${data.id}/model`,
        data.data
      );
      // // console.log("Response:", response.data);
      commit("setModel", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 모델 삭제
  async deleteDeviceModel({ commit }, id) {
    // console.log("CALL deleteDeviceModel()");
    try {
      await axios.delete(`/api/device/model/${id}`);
      commit("setModel", null);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 목록 조회
  async getDevices({ commit }, type) {
    // console.log("CALL getDevices()");
    commit("setDeviceType", type);
    try {
      // type=[heatbench=1|heatboard=2|heatsnow=3|heatcoolbench=4]
      const response = await axios.get(`/api/device/list?type=${type}`);
      // // console.log("Response:", response.data);
      var res = [];
      response.data.forEach(function (item) {
        var status = "none";
        var sensor = "on";
        if (item.connected) {
          if (item.heatCoolStatus.heatOn === "on") {
            status = "on";
          } else {
            status = "off";
          }
        }

        if (item.operModeStatus === "temperature") {
          if (
            item.faultStatus.rapidTemp ||
            item.faultStatus.fixedTemp ||
            item.faultStatus.sensorOpen
          ) {
            sensor = "off";
          }
        } else {
          sensor = "off";
        }

        res.push({
          ...item,

          createdAt: new Date(item.createdAt).toLocaleDateString(),
          updatedAt: new Date(item.updatedAt).toLocaleDateString(),

          rapidTemp: item.faultStatus.rapidTemp,
          fixedTemp: item.faultStatus.fixedTemp,
          sensorOpen: item.faultStatus.sensorOpen,

          heatOn: item.heatCoolStatus.heatOn,
          coolOn: item.heatCoolStatus.coolOn,

          customer: item.customer?.name,
          partner: item.partner?.name,
          address1: item.location.address1,
          address2: item.location.address2,
          location1: item.location.location1,
          location2: item.location.location2,
          location3: item.location.location3,

          status: status,
          sensor: sensor,
        });
      });

      if (type === 1) {
        commit("setBenchDevices", res);
      } else if (type === 2) {
        commit("setBedDevices", res);
      } else {
        commit("setDevices", res);
      }
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 조회
  async getDevice({ commit }, id) {
    // console.log(`CALL getDevice(${id})`);
    try {
      const response = await axios.get(`/api/device/${id}`);
      // console.log("Response:", response.data);
      commit("setDevice", response.data);
      commit("setModels", response.data.info.model);
      commit("setFiles", response.data.info.attachment);
      return response.data;
    } catch (e) {
      console.error("Error :", e);
    }
  },
  // 모델 및 파일 조회
  async getDeviceSub({ commit }, id) {
    // console.log(`CALL getDevice(${id})`);
    try {
      const response = await axios.get(`/api/device/${id}`);
      // console.log("Response:", response.data);
      commit("setModels", response.data.info.model);
      commit("setFiles", response.data.info.attachment);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 설정
  async updateDevice(Null, device_data) {
    // console.log("CALL updateDevice()");
    try {
      await axios.put(`/api/device/${device_data.id}`, device_data.data);
      // // console.log("Response:", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
