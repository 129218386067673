<template>
  <v-list>
    <v-list-item
      :class="menuClass(url, icon)"
      v-for="([title, url, icon], i) in menus"
      :key="i"
      :title="title"
      :value="url"
      @click="goUrl(url)"
    >
      <template v-slot:prepend>
        <v-icon :icon="icon"></v-icon>
      </template>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "CCommonMenu",
  props: {
    menus: Array,
  },
  data: () => ({}),
  computed: {},
  methods: {
    goUrl(url) {
      // console.log(url);
      this.$store.commit("device/setSensorFlag", "-전체-");
      this.$store.commit("user/setTypeFlag", "-전체-");
      this.$router.push({ path: url });
    },
    menuClass(url, icon) {
      if (url !== "") {
        if (icon !== "") {
          return "text-left cursor-pointer";
        } else {
          return "text-left cursor-pointer pl-8";
        }
      }
      return "text-left cursor-default";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.v-list-item--density-default.v-list-item--one-line {
  min-height: 36px;
}

.cursor-default {
  cursor: default;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
