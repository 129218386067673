import axios from "axios";

const state = {
  product: null,
  products: [],
  productModel: null,
  productModels: [],
  productAttribute: null,
  productAttributes: [],
  endState__: null,
};

const getters = {
  product() {
    return state.product;
  },
  products() {
    return state.products;
  },
  productModel() {
    return state.productModel;
  },
  productModels() {
    return state.productModels;
  },
  productAttribute() {
    return state.productAttribute;
  },
  productAttributes() {
    return state.productAttributes;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setProduct(state, payload) {
    state.product = payload;
  },
  setProducts(state, payload) {
    state.products = payload;
  },
  setProductModel(state, payload) {
    state.productModel = payload;
  },
  setProductModels(state, payload) {
    state.productModels = payload;
  },
  setProductAttribute(state, payload) {
    state.productAttribute = payload;
  },
  setProductAttributes(state, payload) {
    state.productAttributes = payload;
  },
  endMutations__() {},
};

const actions = {
  // 제품 추가
  async createProduct({ commit }, product_data) {
    // console.log("CALL createProduct()");
    try {
      const response = await axios.post("/api/product", product_data);
      commit("setProduct", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 제품 조회
  async getProduct({ commit }, id) {
    // console.log("CALL getProduct()");
    try {
      const response = await axios.get(`/api/product/${id}`);
      // console.log("Response:", response.data);
      commit("setProductAttributes", response.data.attribute);
      commit("setProductModels", response.data.model);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 제품 목록 조회 (온열벤치, 에코전기온돌, 제설시스템, 냉난방벤치)
  async getProducts({ commit }) {
    // console.log("CALL getProducts()");
    try {
      const response = await axios.get("/api/product/list");
      // console.log("Response:", response.data);
      commit("setProducts", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 제품 수정
  async updateProduct({ commit }, product_data) {
    // console.log("CALL updateProduct()");
    try {
      const response = await axios.put(
        `/api/product/${product_data.id}`,
        product_data
      );
      commit("setProduct", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 제품 삭제
  async deleteProduct({ commit }, id) {
    // console.log("CALL deleteProduct()");
    try {
      await axios.delete(`/api/product/${id}`);
      // // console.log("Response:", response.data);
      commit("setProduct", null);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 속성 추가
  async createProductAttribute({ commit }, attribute_data) {
    // console.log("CALL createProductAttribute()");
    // console.log(attribute_data);
    try {
      await axios.post(
        `/api/product/${attribute_data.id}/attribute`,
        attribute_data.data
      );
      commit("setProductAttributes", []);
    } catch (e) {
      console.error(e);
    }
  },

  // 속성 수정
  async updateProductAttribute({ commit }, data) {
    // console.log("CALL updateProductAttribute()");
    // console.log(data);
    try {
      const response = await axios.put(
        `/api/product/attribute/${data.id}`,
        data
      );
      commit("setProduct", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 속성 삭제
  async deleteProductAttribute({ commit }, id) {
    // console.log("CALL deleteProductAttribute()");
    try {
      const response = await axios.delete(`/api/product/attribute/${id}`);
      commit("setProduct", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 속성 값 추가
  async createProductAttributeValue({ commit }, value_data) {
    // console.log("CALL createProductAttributeValue()");
    try {
      const response = await axios.post(
        `/api/product/attribute/${value_data.id}/value`,
        value_data.data
      );
      commit("setProduct", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 속성 값 수정
  async updateProductAttributeValue({ commit }, value_data) {
    // console.log("CALL updateProductAttributeValue()");
    try {
      await axios.put(
        `/api/product/attribute/value/${value_data.id}`,
        value_data.data
      );
      commit("setProduct", null);
    } catch (e) {
      console.error(e);
    }
  },

  // 속성 값 삭제
  async deleteProductAttributeValue({ commit }, id) {
    // console.log("CALL deleteProductAttributeValue()");
    try {
      await axios.delete(`/api/product/attribute/value/${id}`);
      commit("setProduct", null);
    } catch (e) {
      console.error(e);
    }
  },

  // 모델 추가
  async createProductModel(Null, model_data) {
    // console.log(`CALL updateProductModel() = ${model_data}`);
    try {
      await axios.post(`/api/product/${model_data.id}/model`, model_data.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 모델 수정
  async updateProductModel(Null, model_data) {
    // console.log("CALL updateProductModel()");
    try {
      await axios.put(`/api/product/model/${model_data.id}`, model_data.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 모델 삭제
  async deleteProductModel(Null, id) {
    // console.log("CALL deleteProductModel()");
    try {
      await axios.delete(`/api/product/model/${id}`);
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
