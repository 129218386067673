<template>
  <v-dialog v-model="dialogVisible" max-width="700px">
    <v-card color="warning" class="pa-2">
      <v-alert
        :color="alert.color"
        :icon="alert.icon"
        :title="alert.title"
        :text="alert.text"
        density="compact"
      ></v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white" variant="outlined" @click="closeDialog"
          >닫기</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CAlertDialog",
  props: {
    alert: {
      color: String,
      icon: String,
      title: String,
      text: String,
    },
  },
  data: () => ({
    dialogVisible: false,
  }),
  methods: {
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("dialogCloseEvent");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.th {
  width: 150;
  background: #eeeeee;
}
</style>
