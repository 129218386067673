<template>
  <v-layout class="text-left">
    <v-app id="inspire">
      <v-main class="mb-12">
        <div class="signup-wrap" style="max-width: 450px">
          <h3 class="title">회원가입</h3>

          <v-form ref="form" v-model="valid" @submit.prevent="createAccountFun">
            <h3 class="sub-title pilsu">아이디 *</h3>
            <div class="control-pane mt-5">
              <v-text-field
                v-model="accountData.account"
                :rules="[id_valid, rules.id]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
                @input="checkUser"
              />
              <div class="col-light-blue mt-1" id="txtUserId">
                첫 글자는 영문이고 영문, 숫자 혼합으로 6자이상 16자이하
              </div>
            </div>

            <h3 class="sub-title pilsu">비밀번호 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.password"
                :rules="[rules.required, rules.password]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="password"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
              <span class="col-light-blue" id="txtUserPw"
                >영문, 숫자, 특수문자(!@#$%^*+=-) 혼합 8자이상 20자이하</span
              >
            </div>

            <h3 class="sub-title pilsu">비밀번호 확인 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="password2"
                :rules="[passwordCheck, rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="password"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title pilsu">이름 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.name"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title">유선전화</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.wiredTel"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title">휴대전화 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.cellTel"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title">이메일 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.email"
                :rules="[rules.email]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title">
              메모 * (회사/기관명입력. 개인고객인 경우 설치 업체 입력.)
            </h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.memo"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
                placeholder="회사/기관명입력. 개인고객일 경우 설치업체명을 입력하세요."
              ></v-text-field>
            </div>

            <h3 class="sub-title">주소 *</h3>
            <div class="control-pane">
              <v-text-field
                v-model="accountData.postcode"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              >
                <template v-slot:append>
                  <v-btn color="grey-darken-1" @click="openPostCode"
                    >우편번호 찾기</v-btn
                  >
                </template>
              </v-text-field>
              <v-text-field
                v-model="accountData.address1"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
              <v-text-field
                v-model="accountData.address2"
                :rules="[rules.required]"
                density="compact"
                clear-icon="mdi-close-circle"
                type="text"
                variant="outlined"
                clearable
                hide-details
                class="ma-1"
              ></v-text-field>
            </div>

            <h3 class="sub-title">언어</h3>
            <div class="control-pane">
              <v-select
                v-model="accountData.language"
                :items="[{ title: '한국어', language: 'KO-kr' }]"
                item-title="title"
                item-value="language"
                variant="outlined"
                density="compact"
                hide-details
                class="ma-1"
              ></v-select>
            </div>

            <div class="control-pane" style="margin-top: 10px">
              <input type="checkbox" id="agreement" v-model="agreement" />
              <label for="agreement">
                <a
                  class="col-light-blue"
                  target="_blank"
                  href="http://smtgoods.com/member/agreement.html"
                >
                  이용약관
                </a>
                에 동의합니다. <b class="col-red">(필수)</b>
              </label>
              <br />
              <input type="checkbox" id="privacy" v-model="privacy" />
              <label for="privacy">
                <a
                  class="col-light-blue"
                  target="_blank"
                  href="http://smtgoods.com/member/privacy.html"
                >
                  개인정보처리방침
                </a>
                에 동의합니다. <b class="col-red">(필수)</b>
              </label>
            </div>

            <div
              class="control-pane"
              style="text-align: center; margin-top: 15px"
            >
              <v-btn
                type="button"
                class="btn bg-grey waves-effect"
                style="height: 38px; width: 40%"
                id="btnGoHome"
                @click="goToLogin"
              >
                취소 </v-btn
              >&nbsp;
              <v-btn
                type="submit"
                class="btn bg-light-blue waves-effect"
                style="height: 38px; width: 40%"
                id="btnSignUp"
              >
                회원가입
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-main>
    </v-app>
    <c-alert-dialog
      v-model="dialogAlert"
      :alert="alertData"
      @dialogCloseEvent="dialogAlert = false"
    />
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import CAlertDialog from "@/components/CAlertDialog.vue";

export default {
  name: "SignupView",
  data: () => ({
    accountData: {
      account: "",
      password: "",
      name: "",
      wiredTel: "",
      cellTel: "",
      email: "",
      memo: "",
      postcode: "",
      address1: "",
      address2: "",
      language: "KO-kr",
    },
    agreement: false,
    privacy: false,
    password2: "",
    id_valid: false,
    password_valid: true,
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      id: (value) => {
        const pattern = /^(?=.*[a-z])^(?=.*\d)[\S]{6,16}$/;
        return pattern.test(value) || "Invalid password";
      },
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[\S]{8,20}$/;
        return pattern.test(value) || "Invalid password";
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      phoneNumber: (value) => {
        const pattern = /^\d{2,3}-\d{3,4}-\d{4}$/; // 예: 010-1234-5678
        return (
          pattern.test(value) ||
          "Invalid phone number. Please use the format XXX-XXXX-XXXX."
        );
      },
    },
    dialogAlert: false,
    alertData: {},
    valid: true,
  }),
  watch: {
    "accountData.account"() {
      this.checkUser();
    },
  },
  components: {
    CAlertDialog,
  },
  computed: {
    passwordCheck() {
      if (this.accountData.password === this.password2) {
        // this.password_valid = true;
        return true;
      } else {
        // this.password_valid = false;
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      findUser: "user/findUser",
      createAccount: "user/createUser",
    }),
    goToLogin() {
      this.$router.push("/login");
    },
    async checkUser() {
      this.id_valid = true;
      let id = await this.findUser(this.accountData.account);
      if (id.exist === false && id.invalid === false) {
        this.id_valid = true;
      } else {
        this.id_valid = false;
      }
    },
    async createAccountFun() {
      // console.log("createAccountFun()");

      if (this.valid === false) {
        this.dialogAlert = true;
        this.alertData = {
          color: "warning",
          icon: "$warning",
          title: "가입 정보 확인 요청",
          text: "가입 정보에 문제가 있습니다. 가입 정보를 확인해 주세요.",
        };
        return 0;
      }

      // await this.checkUser();
      // if (this.id_valid === false) {
      //   this.dialogAlert = true;
      //   this.alertData = {
      //     color: "warning",
      //     icon: "$warning",
      //     title: "아이디 수정 요청",
      //     text: "사용하신 아이디가 적합하지 않습니다. 수정을 부탁드립니다.",
      //   };
      //   return 0;
      // }

      if (document.getElementById("agreement").checked === false) {
        this.dialogAlert = true;
        this.alertData = {
          color: "warning",
          icon: "$warning",
          title: "이용약관 동의 요청",
          text: "이용약관에 동의하지 않았습니다. 이용약관에 동의해 주세요.",
        };
        return 0;
      }

      if (document.getElementById("privacy").checked === false) {
        this.dialogAlert = true;
        this.alertData = {
          color: "warning",
          icon: "$warning",
          title: "개인정보처리방침 동의 요청",
          text: "개인정보처리방침에 동의하지 않았습니다. 이용약관에 동의해 주세요.",
        };
        return 0;
      }

      await this.createAccount(this.accountData);
      await this.goToLogin();
    },
    openPostCode() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          this.accountData.postcode = data.zonecode;
          this.accountData.address1 = data.roadAddress;
        },
      }).open();
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/style/account.css";
.form-control {
  line-height: 1.42857143;
  color: #555;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
