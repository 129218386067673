<template>
  <v-layout class="rounded rounded-md pb-12">
    <c-common-app-bar
      v-if="hide"
      @userInfoEvent="editUserInfo"
      @drawerEvent="drawer = !drawer"
    ></c-common-app-bar>
    <v-navigation-drawer v-model="drawer" v-if="hide" class="pa-1" width="230">
      <c-common-menu
        v-if="type === 'admin'"
        :menus="adminMenus"
      ></c-common-menu>
      <c-common-menu
        v-else-if="type === 'partner'"
        :menus="partnerMenus"
      ></c-common-menu>
      <c-common-menu v-else :menus="userMenus"></c-common-menu>
    </v-navigation-drawer>

    <v-main class="main">
      <router-view />

      <v-btn
        class="snslink"
        :href="kakaourl"
        target="_blank"
        variant="text"
        icon="mdi-calendar"
      >
        <img
          :src="image"
          @mouseover="image = kakaotalkon"
          @mouseout="image = kakaotalk"
        />
      </v-btn>
      <c-edit-my-account
        v-model="dialogEditAccount"
        :data="account"
        @dialogCloseEvent="dialogEditAccount = false"
        @editAccountEvent="editAccountFun"
      ></c-edit-my-account>
      <c-common-footer style="z-index: -1"></c-common-footer>
    </v-main>
    <!-- <c-alert-dialog
      v-model="dialogAlert"
      :alert="alertData"
      @dialogCloseEvent="dialogAlert = false"
    /> -->
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";

import CCommonAppBar from "@/components/CCommonAppBar.vue";
import CCommonMenu from "@/components/CCommonMenu.vue";
import CCommonFooter from "@/components/CCommonFooter.vue";

import CEditMyAccount from "@/components/CEditMyAccount.vue";
// import CAlertDialog from "@/components/CAlertDialog.vue";

export default {
  name: "App",
  created() {
    // console.log(this.$route.path);
  },
  components: {
    CCommonAppBar,
    CCommonMenu,
    CCommonFooter,

    CEditMyAccount,
    // CAlertDialog,
  },
  data: () => ({
    drawer: true,
    windowWidth: 800,
    dialogEditAccount: false,
    image: "/images/snslink_09.png",
    kakaotalk: "/images/snslink_09.png",
    kakaotalkon: "/images/snslink_2_09.png",
    kakaourl: "http://pf.kakao.com/_ugxamxd/chat",
    account: null,

    adminMenus: [
      ["메인페이지", "/admin", "mdi-view-dashboard"],
      ["시스템", "", "mdi-cog-outline"],
      ["회사 관리", "/admin/company", ""],
      ["제품 관리", "/admin/product", ""],
      ["계정 관리", "/admin/account", ""],
      ["장치관리", "", "mdi-thermometer"],
      ["온열벤치", "/admin/device/bench", ""],
      ["에코전기온돌", "/admin/device/bed", ""],
      // ["제설시스템", "/admin/device/3", ""],
      // ["냉난방벤치", "/admin/device/4", ""],
      ["파트너 게시판", "", "mdi-bulletin-board"],
      ["대문관리", "/partner/board/welcome", ""],
      ["공지사항", "/partner/board/notice", ""],
      ["FAQ", "/partner/board/faq", ""],
      ["자료실", "/partner/board/reference", ""],
      ["사용자 게시판", "", "mdi-bulletin-board"],
      ["대문관리", "/user/board/welcome", ""],
      ["공지사항", "/user/board/notice", ""],
      ["FAQ", "/user/board/faq", ""],
      ["자료실", "/user/board/reference", ""],
    ],
    partnerMenus: [
      ["메인페이지", "/partner", "mdi-view-dashboard"],
      ["장치관리", "", "mdi-thermometer"],
      ["온열벤치", "/partner/device/bench", ""],
      ["에코전기온돌", "/partner/device/bed", ""],
      // ["제설시스템", "/partner/device/3", ""],
      // ["냉난방벤치", "/partner/device/4", ""],
      ["게시판", "", "mdi-bulletin-board"],
      ["공지사항", "/partner/board/notice", ""],
      ["FAQ", "/partner/board/faq", ""],
      ["자료실", "/partner/board/reference", ""],
    ],
    userMenus: [
      ["메인페이지", "/user", "mdi-view-dashboard"],
      ["장치관리", "", "mdi-thermometer"],
      ["온열벤치", "/user/device/bench", ""],
      ["에코전기온돌", "/user/device/bed", ""],
      // ["제설시스템", "/user/device/3", ""],
      // ["냉난방벤치", "/user/device/4", ""],
      ["게시판", "", "mdi-bulletin-board"],
      ["공지사항", "/user/board/notice", ""],
      ["FAQ", "/user/board/faq", ""],
      ["자료실", "/user/board/reference", ""],
    ],
    // dialogAlert: false,
    // alertData: {
    //   color: "warning",
    //   icon: "$warning",
    //   title: "스파트폰 가로방향 변경 요청",
    //   text: "스마트폰을 가로방향으로 사용해 주세요.",
    // },
  }),
  computed: {
    hide() {
      if (this.$route.path === "/login" || this.$route.path === "/signup") {
        return false;
      }
      return true;
    },
    name() {
      return this.$store.state.user.name;
    },
    type() {
      return this.$store.state.user.type;
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
      updateUser: "user/updateUser",
    }),
    // updateWidth() {
    //   this.windowWidth = window.innerWidth;
    //   if (this.windowWidth < 600) {
    //     this.dialogAlert = true;
    //   } else {
    //     this.dialogAlert = false;
    //   }
    //   // console.log(this.windowWidth);
    //   // console.log(this.dialogAlert);
    // },
    async editUserInfo() {
      this.account = await this.getUser("");
      this.dialogEditAccount = true;
    },
    async editAccountFun(data) {
      // console.log("editAccountFun()");
      // console.log(data);
      await this.updateUser({ data: data, id: "" });
      await this.getUser("");
    },
  },
  async mounted() {
    await this.getUser("");
    if (this.name === null) {
      this.$router.push({ path: "/" });
    }
    console.log("update 2024.08.20.01");
    // window.addEventListener("resize", this.updateWidth);
  },
  unmounted() {
    // window.removeEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
@import "@/assets/style/v-list.css";
@import "@/assets/style/v-table.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main {
  background: #f1f1f1;
  height: calc(100vh - 48px);
}

.snslink {
  position: fixed !important;
  right: 15px;
  bottom: 40px;
  z-index: 999;
  width: 50px;
  width: 40px;
  overflow: hidden;
}

.v-container {
  max-width: none !important;
}

.v-card--variant-outlined {
  border: thin solid #cccccc !important;
}

// 체크박스, 라디오버튼 위치 조정
.position {
  position: relative;
  top: 10px;
}

// 우편번호 찾기 버튼 위치 조정
.v-input--horizontal .v-input__append {
  margin-inline-start: 4px !important;
}

th {
  background-color: lightgrey;
  font-weight: bold !important;
}

.greyColor {
  background-color: #f1f1f1f1 !important;
}
</style>
