<template>
  <v-layout class="text-left">
    <v-app id="inspire">
      <v-main>
        <body class="theme-haesol">
          <noscript
            >자바스크립트를 지원하지 않는 브라우저에서는 일부 기능을 사용하실 수
            없습니다.</noscript
          >
          <!-- ==================== mcMaster ==================== -->
          <!-- Page Loading Animation-->

          <!-- Overlay For Sidebars -->
          <div class="overlay"></div>
          <!-- Popup -->
          <div class="popup-dark popup-hide"></div>
          <!-- ================================================== -->

          <div class="signup-wrap">
            <h3 class="title">아이디/비밀번호 찾기</h3>
            <ul
              class="nav nav-tabs nav-justified"
              id="findoutTab"
              role="tablist"
            >
              <li class="nav-item active">
                <a
                  class="nav-link"
                  id="uid-tab"
                  data-toggle="tab"
                  href="#uid"
                  role="tab"
                  aria-controls="uid"
                  aria-selected="true"
                  aria-expanded="true"
                  >아이디 찾기</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pwd-tab"
                  data-toggle="tab"
                  href="#pwd"
                  role="tab"
                  aria-controls="pwd"
                  aria-selected="false"
                  >비밀번호 찾기</a
                >
              </li>
            </ul>
            <div class="tab-content" id="findoutTabContent">
              <div
                class="tab-pane active"
                id="uid"
                role="tabpanel"
                aria-labelledby="uid-tab"
              >
                <form id="findIdForm" onsubmit="return false;" method="post">
                  <h3 class="sub-title pilsu">이름</h3>
                  <div class="control-pane">
                    <input
                      type="text"
                      class="form-control"
                      id="userNm"
                      name="userNm"
                      placeholder="이름을 입력하세요."
                    />
                  </div>
                  <h3 class="sub-title pilsu">이메일</h3>
                  <div class="control-pane">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="이메일을 입력하세요."
                    />
                  </div>
                  <div
                    class="control-pane"
                    style="text-align: center; margin-top: 20px"
                  >
                    <button
                      type="button"
                      class="btn bg-grey waves-effect"
                      style="height: 38px; width: 40%"
                      id="btnGoHome1"
                    >
                      취소</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn bg-light-blue waves-effect"
                      style="height: 38px; width: 40%"
                      id="btnFindId"
                    >
                      아이디 찾기
                    </button>
                  </div>
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="pwd"
                role="tabpanel"
                aria-labelledby="pwd-tab"
              >
                <form id="findPwForm" onsubmit="return false;" method="post">
                  <h3 class="sub-title pilsu">아이디</h3>
                  <div class="control-pane">
                    <input
                      type="text"
                      class="form-control"
                      id="userId"
                      name="userId"
                      placeholder="아이디를 입력하세요."
                    />
                  </div>
                  <h3 class="sub-title pilsu">이메일</h3>
                  <div class="control-pane">
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="이메일을 입력하세요."
                    />
                  </div>
                  <div
                    class="control-pane"
                    style="text-align: center; margin-top: 20px"
                  >
                    <button
                      type="button"
                      class="btn bg-grey waves-effect"
                      style="height: 38px; width: 40%"
                      id="btnGoHome2"
                    >
                      취소</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn bg-light-blue waves-effect"
                      style="height: 38px; width: 40%"
                      id="btnFindPw"
                    >
                      비밀번호 찾기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            id="loom-companion-mv3"
            ext-id="liecbddmkiiihnedobmlmillhodjkdmb"
          >
            <section id="shadow-host-companion"></section>
          </div>
          <div
            id="modheader-tool-card"
            style="
              font-size: 16px !important;
              font-family: Roboto, sans-serif, -apple-system, BlinkMacSystemFont,
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
                'Droid Sans', 'Helvetica Neue' !important;
              display: initial !important;
              visibility: visible;
              opacity: 1;
              transition: visibility 0.3s ease 0s, opacity 0.3s ease 0s;
            "
          >
            <div
              class="modheader-shadow-root svelte-2snhol"
              id="modheader-shadow-root-host-el-1708578114137"
            ></div>
          </div>
        </body>
      </v-main>
    </v-app>
  </v-layout>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
