import axios from "axios";

const state = {
  endState__: null,
};

const getters = {
  endGetters__() {
    return null;
  },
};

const mutations = {
  endMutations__() {},
};

const actions = {
  // 장치 자료 추가
  async uploadDeviceData(Null, data) {
    // console.log("CALL uploadDeviceData()");
    try {
      const response = await axios.post(
        `/api/device/${data.id}/attach`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Response:", response.data);
      return response.data;
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 자료 다운로드
  async downloadDeviceData(Null, id) {
    // console.log("CALL downloadDeviceData()");
    try {
      // const response = await axios.get(`/api/device/attach/${id}`);
      const response = await axios({
        url: `/api/device/attach/${id}`,
        method: "GET",
        responseType: "blob",
      });

      // // console.log("Response:", response.data);
      return response;
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 자료 삭제
  async deleteDeviceData(Null, id) {
    // console.log("CALL deleteDeviceData()");
    try {
      await axios.delete(`/api/device/attach/${id}`); // id ?
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 이미지 추가
  async uploadBbsImage(Null, data) {
    // console.log("CALL uploadBbsImage()");
    try {
      await axios.post(`/api/bbs/${data.id}/image`, data.formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 이미지 조회
  async getBbsImage(Null, id) {
    // console.log("CALL getBbsImage()");
    try {
      await axios.get(`/api/bbs/image/${id}`);
      // console.log("Response:", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 이미지 삭제
  async deleteBbsImage(Null, id) {
    // console.log("CALL deleteBbsImage()");
    try {
      await axios.delete(`/api/bbs/image/${id}`);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 자료 추가
  async uploadBbsData(Null, data) {
    // console.log("CALL uploadBbsData()");
    try {
      const response = await axios.post(
        `/api/bbs/${data.id}/attach`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Response:", response.data);
      return response.data;
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 자료 조회
  async getBbsData(Null, id) {
    // console.log("CALL getBbsData()");
    try {
      // const response = await axios.get(`/api/bbs/attach/${id}`);
      const response = await axios({
        url: `/api/bbs/attach/${id}`,
        method: "GET",
        responseType: "blob",
      });
      // console.log("Response:", response.data);
      return response;
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 자료 다운로드
  async downloadBbseData(Null, id) {
    // console.log("CALL downloadBbseData()");
    try {
      // const response = await axios.get(`/api/device/attach/${id}`);
      const response = await axios({
        url: `/api/bbsy/attach/${id}`,
        method: "GET",
        responseType: "blob",
      });

      // // console.log("Response:", response.data);
      return response;
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 게시글 자료 삭제
  async deleteBbsData(Null, id) {
    // console.log("CALL deleteBbsData()");
    try {
      await axios.delete(`/api/bbs/attach/${id}`);
    } catch (e) {
      console.error("Error :", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
