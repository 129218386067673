const state = {
  deviceBenchEditInfoItems: [
    {
      id: 1,
      status: false,
      sensor: true,
      connect_date: "",
      partner: "(주)삼명테크",
      user_name: "(주)삼명테크",
      room_name: "1층 서버실 3",
      floor_number: "1층",
      site_name: "smtech",
      address: "경기 광주시 초월읍 동막골길 150-10",
      ip_address: "",
    },
    {
      id: 2,
      status: false,
      sensor: true,
      connect_date: "",
      partner: "(주)삼명테크",
      user_name: "(주)삼명테크",
      room_name: "1층 서버실 3",
      floor_number: "1층",
      site_name: "smtech1",
      address: "경기 광주시 초월읍 동막골길 150-10",
      ip_address: "",
    },
  ],
  deviceBedEditInfoItems: [
    {
      id: 1,
      status: false,
      sensor: true,
      connect_date: "",
      partner: "(주)삼명테크",
      user_name: "(주)삼명테크",
      room_name: "1층 서버실 3",
      floor_number: "1층",
      site_name: "smtech",
      address: "경기 광주시 초월읍 동막골길 150-10",
      ip_address: "",
    },
    {
      id: 2,
      status: false,
      sensor: true,
      connect_date: "",
      partner: "(주)삼명테크",
      user_name: "(주)삼명테크",
      room_name: "1층 서버실 3",
      floor_number: "1층",
      site_name: "smtech1",
      address: "경기 광주시 초월읍 동막골길 150-10",
      ip_address: "",
    },
  ],
  deviceBenchTempItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      power: true,
      current_temp: "20",
      out_temp: "21",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      power: false,
      current_temp: "20",
      out_temp: "21",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      power: true,
      current_temp: "21",
      out_temp: "21",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      power: true,
      current_temp: "21",
      out_temp: "20",
    },
  ],
  deviceBenchTimeItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      timer: false,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: false,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-23:00",
    },
  ],
  deviceBenchNetworkItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      communication_cycle: "60",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      communication_cycle: "50",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      communication_cycle: "55",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      communication_cycle: "60",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pwpw",
    },
  ],
  deviceBenchConfigItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      remote_control: "재시작",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 3,
      time: "2024-01-05 12:12:20",
      name: "관리자",
      remote_control: "알림/고장 초기화",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 2,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      remote_control: "-",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "8",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 1,
      time: "2024-01-05 11:30:30",
      name: "관리자",
      remote_control: "공장설정초기화",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
  ],
  deviceBenchEditStateItems: [
    {
      time: "2024-03-07 14:44:22",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:44:06",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:41:03",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:36:50",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2023-12-22 16:34:26",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2023-12-22 16:24:15",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
  ],
  deviceBedTempItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      power: true,
      current_temp: "20",
      out_temp: "21",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      power: false,
      current_temp: "20",
      out_temp: "21",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      power: true,
      current_temp: "21",
      out_temp: "21",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      power: true,
      current_temp: "21",
      out_temp: "20",
    },
  ],
  deviceBedTimeItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      timer: false,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: false,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-24:00",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      timer: true,
      mon1: true,
      mon1_time: "07:30-10:00",
      mon2: true,
      mon2_time: "12:30-24:00",
      tue1: true,
      tue1_time: "07:30-10:00",
      tue2: true,
      tue2_time: "12:30-24:00",
      wed1: true,
      wed1_time: "07:30-10:00",
      wed2: true,
      wed2_time: "12:30-24:00",
      thu1: true,
      thu1_time: "07:30-10:00",
      thu2: true,
      thu2_time: "12:30-24:00",
      fri1: true,
      fri1_time: "07:30-10:00",
      fri2: true,
      fri2_time: "12:30-24:00",
      sat1: true,
      sat1_time: "07:30-10:00",
      sat2: true,
      sat2_time: "12:30-24:00",
      sun1: true,
      sun1_time: "07:30-10:00",
      sun2: true,
      sun2_time: "12:30-23:00",
    },
  ],
  deviceBedNetworkItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      communication_cycle: "60",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 3,
      time: "2024-01-05 12:14:20",
      name: "관리자",
      communication_cycle: "50",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 2,
      time: "2024-01-05 12:12:30",
      name: "관리자",
      communication_cycle: "55",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pw",
    },
    {
      id: 1,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      communication_cycle: "60",
      time_zone: "+09:00",
      wifi_ssid: "ssid",
      wifi_pw: "pwpw",
    },
  ],
  deviceBedConfigItems: [
    {
      id: 4,
      time: "2024-01-05 12:15:20",
      name: "관리자",
      remote_control: "재시작",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 3,
      time: "2024-01-05 12:12:20",
      name: "관리자",
      remote_control: "알림/고장 초기화",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 2,
      time: "2024-01-05 12:10:30",
      name: "관리자",
      remote_control: "-",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "8",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
    {
      id: 1,
      time: "2024-01-05 11:30:30",
      name: "관리자",
      remote_control: "공장설정초기화",
      screen_lock: "OFF",
      out_temp_view: "OFF",
      temp_setting: "5",
      reboot_setting: "ON",
      reboot_time: "3:30",
      cycle_mode_on: "10",
      cycle_mode_off: "10",
      backlight_level_min: "5",
      backlight_level_max: "100",
      heating_temp_min: "15",
      heating_temp_max: "60",
      cooling_temp_min: "10",
      cooling_temp_max: "20",
    },
  ],
  deviceBedEditStateItems: [
    {
      time: "2024-03-07 14:44:22",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:44:06",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:41:03",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2024-03-07 11:36:50",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2023-12-22 16:34:26",
      run_stop: "On",
      input_output: "난방중단",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
    {
      time: "2023-12-22 16:24:15",
      run_stop: "On",
      input_output: "난방출력",
      temp: "장애",
      in_temp: "정상",
      temp_sensor: "센서정상",
    },
  ],
  test: "",
  endState__: null,
};

const getters = {
  test() {
    return state.test;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setTest(state, payload) {
    state.test = payload;
  },
  endMutations__() {},
};

const actions = {
  // actTest({ state, commit, dispatch }, args) {
  //     // console.log('CALL actTest()')
  //     return 'test'
  // },
  actTest() {
    // console.log("CALL actTest()");
    return "test";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
