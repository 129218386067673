import axios from "axios";

const state = {
  company: null,
  companys: [],
  endState__: null,
};

const getters = {
  company() {
    return state.company;
  },
  companys() {
    return state.companys;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setCompany(state, payload) {
    state.company = payload;
  },
  setCompanys(state, payload) {
    state.companys = payload;
  },
  endMutations__() {},
};

const actions = {
  // 회사 추가
  async createCompany({ commit }, company_data) {
    // console.log("CALL createCompany()");
    try {
      const response = await axios.post("/api/company", company_data);
      commit("setCompany", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 회사 조회
  async getCompany({ commit }, id) {
    // console.log("CALL getCompany()", id);
    try {
      const response = await axios.get(`/api/company/${id}`);
      // // console.log("Response:", response.data);
      commit("setCompany", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 회사 목록 조회
  async getCompanys({ commit }) {
    // console.log("CALL getCompanys()");
    try {
      const response = await axios.get("/api/company/list");
      // console.log("Response:", response.data);
      commit("setCompanys", response.data);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 회사 수정
  async updateCompany({ commit }, company_data) {
    // console.log("CALL updateCompany()");
    try {
      const response = await axios.put(
        `/api/company/${company_data.id}`,
        company_data
      );
      commit("setCompany", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  // 회사 삭제
  async deleteCompany({ commit }, id) {
    // console.log("CALL deleteCompany()");
    try {
      await axios.delete(`/api/company/${id}`);
      commit("setCompany", null);
    } catch (e) {
      console.error("Error :", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
