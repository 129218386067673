<template>
  <v-layout class="rounded rounded-md">
    <v-main
      class="d-flex align-center justify-center"
      style="min-height: 300px"
    >
      화면 전환 중...
    </v-main>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
// @ is an alias to /src

export default {
  name: "HomeView",
  async created() {
    await this.getUser();
    // 화면전환
    // console.log(this.$store.state.user.type);
    if (this.type === "admin") {
      this.$router.push({ path: "/admin" });
    } else if (this.type === "partner") {
      this.$router.push({ path: "/partner" });
    } else if (this.type === "government" || this.type === "customer") {
      this.$router.push({ path: "/user" });
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  data: () => ({}),
  components: {},
  computed: {
    type() {
      return this.$store.state.user.type;
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
    }),
  },
};
</script>
