import axios from "axios";

const state = {
  deviceInfo: [],
  deviceTemp: [],
  deviceTime: [],
  deviceNetwork: [],
  deviceConfig: [],
  deviceRemote: [],
  deviceStatus: [],
  devicePower: [],
  deviceTotalPowerTime: 0,
  loading: false,
  endState__: null,
};

const getters = {
  deviceInfo() {
    return state.deviceInfo;
  },
  deviceTemp() {
    return state.deviceTemp;
  },
  deviceTime() {
    return state.deviceTime;
  },
  deviceNetwork() {
    return state.deviceNetwork;
  },
  deviceConfig() {
    return state.deviceConfig;
  },
  deviceRemote() {
    return state.deviceRemote;
  },
  deviceStatus() {
    return state.deviceStatus;
  },
  devicePower() {
    return state.devicePower;
  },
  deviceTotalPowerTime() {
    return state.deviceTotalPowerTime;
  },
  loading() {
    return state.loading;
  },

  endGetters__() {
    return null;
  },
};

const mutations = {
  setDeviceInfo(state, payload) {
    state.deviceInfo = payload;
  },
  setDeviceTemp(state, payload) {
    state.deviceTemp = payload;
  },
  setDeviceTime(state, payload) {
    state.deviceTime = payload;
  },
  setDeviceNetwork(state, payload) {
    state.deviceNetwork = payload;
  },
  setDeviceConfig(state, payload) {
    state.deviceConfig = payload;
  },
  setDeviceRemote(state, payload) {
    state.deviceRemote = payload;
  },
  setDeviceStatus(state, payload) {
    state.deviceStatus = payload;
  },
  setDevicePower(state, payload) {
    state.devicePower = payload;
  },
  setDeviceTotalPowerTime(state, payload) {
    state.deviceTotalPowerTime = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  endMutations__() {},
};

const actions = {
  // 장치 정보 변경 내역 조회
  async getDeviceInfo({ commit }, id) {
    // console.log(`CALL getDeviceInfo(${id})`);
    try {
      const response = await axios.get(`/api/device/${id}/history/info`);
      // console.log("Response:", response.data);
      let deviceInfos = [];
      response.data.forEach((device) => {
        let oneDeviceInfo = {
          date: device.date,
          name: device.user.name,
          group: device.info.group,
          partner: device.info.partner?.name ?? "",
          customer: device.info.customer?.name ?? "",
          location1: device.info.location?.location1 ?? "",
          location2: device.info.location?.location2 ?? "",
          location3: device.info.location?.location3 ?? "",
          address1: device.info.location?.address1 ?? "",
          address2: device.info.location?.address2 ?? "",
          address:
            (device.info.location?.address1 ?? "") +
            " " +
            (device.info.location?.address2 ?? ""),
        };
        deviceInfos.push(oneDeviceInfo);
      });
      // console.log(deviceInfos);
      commit("setDeviceInfo", deviceInfos);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 설정 변경 내역 조회
  async getDeviceConfigure({ commit }, id) {
    // console.log("CALL getDeviceConfigure()");
    try {
      const response = await axios.get(`/api/device/${id}/history/configure`);
      // // console.log("Response:", response.data);

      let deviceTemp = [];
      let deviceTime = [];
      let deviceNetwork = [];
      let deviceConfig = [];
      response.data.forEach((device) => {
        let scheduleList = device.Configure.schedule.list;
        // // console.log("scheduleList:", scheduleList);
        // // console.log("scheduleList.length:", scheduleList.length);
        if (typeof scheduleList === "undefined") {
          scheduleList = [
            [0, false, "00:00", "07:00"],
            [0, false, "14:00", "15:00"],
            [0, false, "18:00", "23:59"],
            [1, false, "00:00", "07:00"],
            [1, false, "14:00", "15:00"],
            [1, false, "18:00", "23:59"],
            [2, false, "00:00", "07:00"],
            [2, false, "14:00", "15:00"],
            [2, false, "18:00", "23:59"],
            [3, false, "00:00", "07:00"],
            [3, false, "14:00", "15:00"],
            [3, false, "18:00", "23:59"],
            [4, false, "00:00", "07:00"],
            [4, false, "14:00", "15:00"],
            [4, false, "18:00", "23:59"],
            [5, false, "00:00", "07:00"],
            [5, false, "14:00", "15:00"],
            [5, false, "18:00", "23:59"],
            [6, false, "00:00", "07:00"],
            [6, false, "14:00", "15:00"],
            [6, false, "18:00", "23:59"],
          ];
        } else if (scheduleList.length < 21) {
          scheduleList = [
            [0, false, "00:00", "07:00"],
            [0, false, "14:00", "15:00"],
            [0, false, "18:00", "23:59"],
            [1, false, "00:00", "07:00"],
            [1, false, "14:00", "15:00"],
            [1, false, "18:00", "23:59"],
            [2, false, "00:00", "07:00"],
            [2, false, "14:00", "15:00"],
            [2, false, "18:00", "23:59"],
            [3, false, "00:00", "07:00"],
            [3, false, "14:00", "15:00"],
            [3, false, "18:00", "23:59"],
            [4, false, "00:00", "07:00"],
            [4, false, "14:00", "15:00"],
            [4, false, "18:00", "23:59"],
            [5, false, "00:00", "07:00"],
            [5, false, "14:00", "15:00"],
            [5, false, "18:00", "23:59"],
            [6, false, "00:00", "07:00"],
            [6, false, "14:00", "15:00"],
            [6, false, "18:00", "23:59"],
          ];
        }

        let oneDeviceTemp = {
          date: device.date, // 날짜
          name: device.user.name, // 담당자
          onOff: device.Configure.onOff, // 전원설정
          targetTemp: device.Configure.targetTemp, // 현재온도설정(℃)
          triggerEnvTemp: device.Configure.triggerEnvTemp, // 외부온도설정(℃)
        };

        // let oneDeviceTime = {
        //   date: device.date, // 날짜
        //   name: device.user.name, // 담당자
        //   scheduleEnable: device.Configure.schedule.enable, // 타이머, 스케줄리스트 [ [0, false, "hhmm", "hhmm"], [0, false, "hhmm", "hhmm"] ... ]

        //   sun1: scheduleList[0][1],
        //   sun1time1: scheduleList[0][2],
        //   sun1time2: scheduleList[0][3],
        //   sun2: scheduleList[1][1],
        //   sun2time1: scheduleList[1][2],
        //   sun2time2: scheduleList[1][3],
        //   sun3: scheduleList[2][1],
        //   sun3time1: scheduleList[2][2],
        //   sun3time2: scheduleList[2][3],

        //   mon1: scheduleList[3][1],
        //   mon1time1: scheduleList[3][2],
        //   mon1time2: scheduleList[3][3],
        //   mon2: scheduleList[4][1],
        //   mon2time1: scheduleList[4][2],
        //   mon2time2: scheduleList[4][3],
        //   mon3: scheduleList[5][1],
        //   mon3time1: scheduleList[5][2],
        //   mon3time2: scheduleList[5][3],

        //   tue1: scheduleList[6][1],
        //   tue1time1: scheduleList[6][2],
        //   tue1time2: scheduleList[6][3],
        //   tue2: scheduleList[7][1],
        //   tue2time1: scheduleList[7][2],
        //   tue2time2: scheduleList[7][3],
        //   tue3: scheduleList[8][1],
        //   tue3time1: scheduleList[8][2],
        //   tue3time2: scheduleList[8][3],

        //   wed1: scheduleList[9][1],
        //   wed1time1: scheduleList[9][2],
        //   wed1time2: scheduleList[9][3],
        //   wed2: scheduleList[10][1],
        //   wed2time1: scheduleList[10][2],
        //   wed2time2: scheduleList[10][3],
        //   wed3: scheduleList[11][1],
        //   wed3time1: scheduleList[11][2],
        //   wed3time2: scheduleList[11][3],

        //   thu1: scheduleList[12][1],
        //   thu1time1: scheduleList[12][2],
        //   thu1time2: scheduleList[12][3],
        //   thu2: scheduleList[13][1],
        //   thu2time1: scheduleList[13][2],
        //   thu2time2: scheduleList[13][3],
        //   thu3: scheduleList[14][1],
        //   thu3time1: scheduleList[14][2],
        //   thu3time2: scheduleList[14][3],

        //   fri1: scheduleList[15][1],
        //   fri1time1: scheduleList[15][2],
        //   fri1time2: scheduleList[15][3],
        //   fri2: scheduleList[16][1],
        //   fri2time1: scheduleList[16][2],
        //   fri2time2: scheduleList[16][3],
        //   fri3: scheduleList[17][1],
        //   fri3time1: scheduleList[17][2],
        //   fri3time2: scheduleList[17][3],

        //   sat1: scheduleList[18][1],
        //   sat1time1: scheduleList[18][2],
        //   sat1time2: scheduleList[18][3],
        //   sat2: scheduleList[19][1],
        //   sat2time1: scheduleList[19][2],
        //   sat2time2: scheduleList[19][3],
        //   sat3: scheduleList[20][1],
        //   sat3time1: scheduleList[20][2],
        //   sat3time2: scheduleList[20][3],
        // };

        let oneDeviceTime = {
          date: device.date, // 날짜
          name: device.user.name, // 담당자
          scheduleEnable: device.Configure.schedule.enable ? "사용 " : "정지 ",

          sun:
            (scheduleList[0][1] ? "사용" : "정지" + " ") +
            scheduleList[0][2] +
            "~" +
            scheduleList[0][3] +
            ", " +
            (scheduleList[1][1] ? "사용" : "정지" + " ") +
            scheduleList[1][2] +
            "~" +
            scheduleList[1][3] +
            ", " +
            (scheduleList[2][1] ? "사용" : "정지" + " ") +
            scheduleList[2][2] +
            "~" +
            scheduleList[2][3],

          mon:
            (scheduleList[3][1] ? "사용" : "정지" + " ") +
            scheduleList[3][2] +
            "~" +
            scheduleList[3][3] +
            ", " +
            (scheduleList[4][1] ? "사용" : "정지" + " ") +
            scheduleList[4][2] +
            "~" +
            scheduleList[4][3] +
            ", " +
            (scheduleList[5][1] ? "사용" : "정지" + " ") +
            scheduleList[5][2] +
            "~" +
            scheduleList[5][3],

          tue:
            (scheduleList[6][1] ? "사용" : "정지" + " ") +
            scheduleList[6][2] +
            "~" +
            scheduleList[6][3] +
            ", " +
            (scheduleList[7][1] ? "사용" : "정지" + " ") +
            scheduleList[7][2] +
            "~" +
            scheduleList[7][3] +
            ", " +
            (scheduleList[8][1] ? "사용" : "정지" + " ") +
            scheduleList[8][2] +
            "~" +
            scheduleList[8][3],

          wed:
            (scheduleList[9][1] ? "사용" : "정지" + " ") +
            scheduleList[9][2] +
            "~" +
            scheduleList[9][3] +
            ", " +
            (scheduleList[10][1] ? "사용" : "정지" + " ") +
            scheduleList[10][2] +
            "~" +
            scheduleList[10][3] +
            ", " +
            (scheduleList[11][1] ? "사용" : "정지" + " ") +
            scheduleList[11][2] +
            "~" +
            scheduleList[11][3],

          thu:
            (scheduleList[12][1] ? "사용" : "정지" + " ") +
            scheduleList[12][2] +
            "~" +
            scheduleList[12][3] +
            ", " +
            (scheduleList[13][1] ? "사용" : "정지" + " ") +
            scheduleList[13][2] +
            "~" +
            scheduleList[13][3] +
            ", " +
            (scheduleList[14][1] ? "사용" : "정지" + " ") +
            scheduleList[14][2] +
            "~" +
            scheduleList[14][3],

          fri:
            (scheduleList[15][1] ? "사용" : "정지" + " ") +
            scheduleList[15][2] +
            "~" +
            scheduleList[15][3] +
            ", " +
            (scheduleList[16][1] ? "사용" : "정지" + " ") +
            scheduleList[16][2] +
            "~" +
            scheduleList[16][3] +
            ", " +
            (scheduleList[17][1] ? "사용" : "정지" + " ") +
            scheduleList[17][2] +
            "~" +
            scheduleList[17][3],

          sat:
            (scheduleList[18][1] ? "사용" : "정지" + " ") +
            scheduleList[18][2] +
            "~" +
            scheduleList[18][3] +
            ", " +
            (scheduleList[19][1] ? "사용" : "정지" + " ") +
            scheduleList[19][2] +
            "~" +
            scheduleList[19][3] +
            ", " +
            (scheduleList[20][1] ? "사용" : "정지" + " ") +
            scheduleList[20][2] +
            "~" +
            scheduleList[20][3],
        };

        let oneDeviceNetwork = {
          date: device.date, // 날짜
          name: device.user.name, // 담당자
          commInterval: device.Configure.commInterval, // 통신주기 (초)
          timezone: device.Configure.timezone, // 타임존 (UTC)
          wifiSSID: device.Configure.wifiSSID, // Wifi SSID
          wifiPassword: device.Configure.wifiPassword, // Wifi 비밀번호
        };
        let oneDeviceConfig = {
          date: device.date, // 날짜
          name: device.user.name, // 담당자
          screenLock: device.Configure.screenLock ? "사용" : "중지", // 화면잠금
          dispTemp: device.Configure.dispTemp ? "표시" : "미표시", // 외부온도표시
          targetTempSensitivity: device.Configure.targetTempSensitivity, // 가동온도설정
          rapidTempThreshold: device.Configure.rapidTempThreshold, // 온도급변경계값 설정
          dailyRebootEnable: device.Configure.dailyReboot.enable, // 재부팅 설정/시간 ({ enable: false, time: "hhmm"})
          dailyRebootTime: device.Configure.dailyReboot.time, // 재부팅 설정/시간 ({ enable: false, time: "hhmm"})
          periodOnMin: device.Configure.periodOnMin, // 주기모드 (분) 최소
          periodOffMin: device.Configure.periodOffMin, // 주기모드 (분) 최대
          backlightShow: device.Configure.backlight.show ?? 0, // 백라이트 레벨(%) ({show: 0, idle: 0 })
          backlightIdle: device.Configure.backlight.idle ?? 0, // 백라이트 레벨(%) ({show: 0, idle: 0 })
          heatLowLimit: device.Configure.heatLowLimit, // 난방온도범위최소
          heatHighLimit: device.Configure.heatHighLimit, // 난방온도범위최대
        };

        deviceTemp.push(oneDeviceTemp);
        deviceTime.push(oneDeviceTime);
        deviceNetwork.push(oneDeviceNetwork);
        deviceConfig.push(oneDeviceConfig);
      });
      commit("setDeviceTemp", deviceTemp);
      commit("setDeviceTime", deviceTime);
      commit("setDeviceNetwork", deviceNetwork);
      commit("setDeviceConfig", deviceConfig);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 설정 변경 내역 조회
  async getDeviceRemote({ commit }, id) {
    // console.log("CALL getDeviceRemote()");
    try {
      const response = await axios.get(`/api/device/${id}/history/remote`);
      // console.log("Response:", response.data);

      let deviceRemote = [];
      response.data.forEach((device) => {
        let oneDeviceRemote = {
          date: device.date, // 날짜
          name: device.user.name, // 담당자
        };

        if (device.remoteOper.resetAlarmFault) {
          oneDeviceRemote.remote = "알림/고장 초기화";
        }
        if (device.remoteOper.reboot) {
          oneDeviceRemote.remote = "재시작";
        }
        if (device.remoteOper.resetFactory) {
          oneDeviceRemote.remote = "공장설정 초기화";
        }
        if (device.remoteOper.update) {
          oneDeviceRemote.remote = "업데이트";
        }

        deviceRemote.push(oneDeviceRemote);
      });
      commit("setDeviceRemote", deviceRemote);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 상태 변경 내역 조회
  async getDeviceStatus({ commit }, data) {
    // console.log("CALL getDeviceStatus()");
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `/api/device/${data.id}/history/status?from=${data.date.from}&to=${data.date.to}`
      );
      // console.log("Response:", response.data);

      let deviceStatus = [];
      response.data.forEach((device) => {
        let currTemp = device.status.currTemp; // 현재온도
        let envTemp = device.status.envTemp; // 외부온도
        if (currTemp > 126) {
          currTemp = "-";
        }
        if (envTemp > 126) {
          envTemp = "-";
        }

        let oneDeviceStatus = {
          date: device.date, // 날짜
          onOffStatus: device.status.onOffStatus, // 운전상태
          currTemp: currTemp, // 현재온도
          envTemp: envTemp, // 외부온도
          heatOn: device.status.heatCoolStatus.heatOn ? "난방" : "정지",
          fixedTemp: device.status.faultStatus.fixedTemp ? "오류" : "정상",
          rapidTemp: device.status.faultStatus.rapidTemp ? "오류" : "정상",
          sensorOpen: device.status.faultStatus.sensorOpen ? "오류" : "정상",
        };
        deviceStatus.push(oneDeviceStatus);
      });
      commit("setDeviceStatus", deviceStatus);
      commit("setLoading", false);
    } catch (e) {
      console.error("Error :", e);
    }
  },

  // 장치 전력 변경 내역 조회
  async getDevicePower({ commit }, data) {
    // console.log("CALL getDevicePower()");
    try {
      const response = await axios.get(
        `/api/device/${data.id}/history/power?from=${data.date.from}&to=${data.date.to}`
      );
      // console.log("Response:", response.data);

      let devicePower = [];
      let deviceTotalPowerTime = 0;

      if (response.data !== null) {
        response.data.forEach((device) => {
          const startDate = new Date(device[0]);
          const endDate = new Date(device[1]);
          const timeDifferenceInMillis = endDate - startDate;
          const differenceInSeconds = timeDifferenceInMillis / 1000;

          let oneDevicePower = {
            start: device[0],
            end: device[1],
            value: differenceInSeconds,
          };
          deviceTotalPowerTime =
            deviceTotalPowerTime + Number(differenceInSeconds);
          devicePower.push(oneDevicePower);
        });
      }

      // console.log(deviceTotalPowerTime);
      commit("setDeviceTotalPowerTime", deviceTotalPowerTime);
      commit("setDevicePower", devicePower);
    } catch (e) {
      console.error("Error :", e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
