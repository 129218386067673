<template>
  <v-app-bar :color="userColor" elevation="0">
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer"
      ></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title class="text-left">
      <router-link
        :to="url"
        class="mt-0"
        style="color: #fff; text-decoration-line: none"
        ><img src="/images/logo_top.png" style="height: 25px" /> 온도조절기
        중앙관리시스템</router-link
      >
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn style="color: #fff" @click="userInfo">
      {{ name }} / {{ position }} 님
    </v-btn>

    <v-btn
      style="color: #fff; font-weight: bold"
      @click="handleLogout"
      variant="outlined"
    >
      로그아웃
    </v-btn>
  </v-app-bar>
  <!-- <v-system-bar color="#464646"></v-system-bar> -->
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CCommonAppBar",
  props: {},
  data: () => ({}),
  computed: {
    hide() {
      if (
        this.$route.path === "/member/login" ||
        this.$route.path === "/member/signup"
      ) {
        return false;
      }
      return true;
    },
    name() {
      return this.$store.state.user.name;
    },
    position() {
      let type = this.$store.state.user.type;
      if (type === "admin") return "관리자";
      if (type === "partner") return "파트너";
      if (type === "government") return "기관";
      if (type === "customer") return "고객";
      return "";
    },
    userColor() {
      let type = this.$store.state.user.type;
      if (type === "admin") return "#393939";
      if (type === "partner") return "#FB8C00";
      if (type === "government") return "#039BE5";
      if (type === "customer") return "#039BE5";
      return "#039BE5";
    },
    url() {
      let type = this.$store.state.user.type;
      let url = "";

      switch (type) {
        case "admin":
          url = "/admin";
          break;

        case "partner":
          url = "/partner";
          break;

        default:
          url = "/user";
          break;
      }

      return url;
    },
  },
  methods: {
    ...mapActions({
      logout: "user/logout", // 'user/logout' 액션을 'logout' 메서드로 매핑합니다.
    }),
    clickLogo(e) {
      e.preventDefault();

      let type = this.$store.state.user.type;
      let url = "";

      switch (type) {
        case "admin":
          url = "/admin";
          break;

        case "partner":
          url = "/partner";
          break;

        default:
          url = "/user";
          break;
      }
      this.$router.push(url);
    },
    async handleLogout() {
      if (this.logoutInProgress) {
        return;
      }

      this.logoutInProgress = true;

      try {
        // 로그아웃을 시도합니다.
        await this.logout();

        // 로그아웃이 성공한 경우에는 홈 화면으로 이동하도록 설정합니다.
        this.$router.push({ path: "/" });
      } catch (error) {
        // 로그아웃 도중 에러가 발생한 경우 에러 메시지를 표시하거나 적절한 액션을 취합니다.
        console.error("로그아웃 도중 에러가 발생했습니다.", error);
      }
    },
    userInfo() {
      this.$emit("userInfoEvent");
    },
    drawer() {
      this.$emit("drawerEvent");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
