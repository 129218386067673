import axios from "axios";

const state = {
  typeFlag: "-전체-",

  name: null,
  type: null,
  user: null,
  users: [],
  endState__: null,
};

const getters = {
  typeFlag() {
    return state.typeFlag;
  },
  name() {
    return state.name;
  },
  type() {
    return state.type;
  },
  user() {
    return state.user;
  },
  users() {
    return state.users;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setTypeFlag(state, payload) {
    state.typeFlag = payload;
  },
  setName(state, payload) {
    state.name = payload;
  },
  setType(state, payload) {
    state.type = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  endMutations__() {},
};

const actions = {
  async findUser(Null, account) {
    try {
      const response = await axios.get(`/api/user/eligible?account=${account}`);
      // console.log(response.data);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  },
  async auth({ commit }, auth) {
    // console.log("CALL auth()");
    try {
      const response = await axios.post("/api/user/login", auth);

      // // console.log(response.data);

      commit("setName", response.data.name);
      commit("setType", response.data.type);
    } catch (e) {
      console.error("Login failed:", e);
      commit("setName", null);
      commit("setType", null);
      throw e;
    }
  },
  async logout({ commit }) {
    try {
      await axios.get("/api/user/logout");
      commit("setName", null);
      commit("setType", null);
    } catch (e) {
      console.error("Login failed:", e);
      commit("setName", null);
      commit("setType", null);
      throw e;
    }
  },
  async createUser({ commit }, user_data) {
    // console.log("CALL createUser()");
    try {
      const response = await axios.post("/api/user", user_data);
      commit("setUser", response.data);
      // console.log(response.data);
    } catch (e) {
      console.error(e);
    }
  },

  async getUser({ commit }, id) {
    // console.log("CALL getUser()");
    try {
      if (id === "") {
        const response = await axios.get("/api/user");
        commit("setName", response.data.name);
        commit("setType", response.data.companyType);
        return response.data;
      } else {
        const response = await axios.get(`/api/user/${id}`);
        // console.log(response.data);
        commit("setUser", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async getUsers({ commit }) {
    // console.log("CALL getUsers()");
    try {
      const response = await axios.get("/api/user/list");
      // console.log(response.data);
      commit("setUsers", response.data);
    } catch (e) {
      console.error(e);
    }
  },

  async updateUser({ commit }, user_data) {
    // console.log(`CALL updateUser()`);
    try {
      if (user_data.id === "") {
        const response = await axios.put("/api/user", user_data.data);
        // console.log(response.data);
        commit("setName", response.data.name);
        commit("setType", response.data.companyType);
      } else {
        const response = await axios.put(
          `/api/user/${user_data.id}`,
          user_data.data
        );
        // console.log(response.data);
        commit("setUser", response.data);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async deleteUser({ commit }, id) {
    // console.log("CALL deleteUser()");
    try {
      await axios.delete(`/api/user/${id}`);
      commit("setUser", null);
    } catch (e) {
      console.error(e);
    }
  },

  async changeUsers({ commit }, id) {
    // 다른 아이디로 로그인 - 패스워드를 무시하고 접속하는 방법?
    // console.log("CALL changeUsers()");
    try {
      const response = await axios.get(`/api/user/change/${id}`);
      commit("setUsers", response.data);
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
