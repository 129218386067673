<template>
  <v-app class="absolute-element">
    <v-main class="center-container">
      <c-common-login :saveId="saveId"></c-common-login>
    </v-main>
  </v-app>
</template>

<script>
import CCommonLogin from "@/components/CCommonLogin.vue";

export default {
  name: "LoginView",
  data() {
    return {
      saveId: "",
    };
  },
  components: {
    CCommonLogin,
  },
  async created() {
    let saveId = localStorage.getItem("saveId") || "";
    this.saveId = saveId;
  },
  computed: {},
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/style/login.css";
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* This ensures the container takes at least the full height of the viewport */
}
.absolute-element {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
</style>
