<template>
  <v-container>
    <v-form ref="loginForm" v-model="valid" @submit.prevent="handleLogin">
      <div class="login-wrapper">
        <div class="head">
          <div class="logo">
            <v-row justify="center">
              <v-col cols="12" align="center">
                <v-img
                  src="/images/logo_top.png"
                  width="85"
                  title="(주)삼명테크"
                />
              </v-col>
            </v-row>
          </div>
          <div class="logo_txt fx15 pa_bot5">친환경 난방문화의 선두주자</div>
          <div class="logo_txt fx28 pa_bot5">(주)삼명테크</div>
          <div class="logo_txt fx14">- 온도조절기 관리시스템 -</div>
        </div>
        <div class="body">
          <div class="login-logo">
            <div class="fx19 pa_bot10">
              <span class="red">에코전기온돌</span>
              &nbsp;난방시스템
            </div>
            <div class="login_img pa_bot5">
              <img src="/images/login_img_s.jpg" title="(주)삼명테크" />
            </div>
            <div class="fx14 fbold blue">인간과 환경을 생각하는 기업</div>
            <div class="fx25">SAMMYUNG TECH</div>
          </div>
          <div class="login-body">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="account"
                  label="아이디"
                  variant="underlined"
                  required
                  :rules="[(v) => !!v || '아이디는 필수입니다.']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  label="비밀번호"
                  type="password"
                  variant="underlined"
                  required
                  :rules="[(v) => !!v || '비밀번호는 필수입니다.']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="idSave"
                  label="아이디 저장"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn type="submit" class="btn_login" color="primary">
                  로그인
                </v-btn>
              </v-col>
            </v-row>
            <div class="sign_go">
              <router-link to="/signup">회원가입</router-link>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CCommonLogin",
  props: {
    saveId: String,
  },
  data: () => ({
    account: null,
    password: null,
    idSave: true,
    valid: true,
  }),
  computed: {
    type() {
      return this.$store.state.user.type;
    },
  },
  methods: {
    ...mapActions({
      login: "user/auth", // 'user/auth' 액션을 'login' 메서드로 매핑합니다.
    }),

    async handleLogin() {
      if (this.$refs.loginForm.validate()) {
        try {
          await this.login({ account: this.account, password: this.password });

          if (this.idSave) {
            localStorage.setItem("saveId", this.account);
          } else {
            localStorage.setItem("saveId", "");
          }

          if (this.type === "admin") {
            this.$router.push({ path: "/admin" });
          } else if (this.type === "partner") {
            this.$router.push({ path: "/partner" });
          } else {
            this.$router.push({ path: "/user" });
          }
        } catch (error) {
          this.password = null;
          // console.log(error);
        }
      }
    },
  },
  mounted() {
    this.account = this.saveId;
  },
};
</script>

<style scoped lang="scss"></style>
